import axios from 'axios';

// Retry interceptor function
import axiosRetry from 'axios-retry';

// Pass the axios instance to the retry function  and call it
axiosRetry(axios, { 
  retries: 1, // Number of retries
  retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000ms
  retryCondition(error) {
    // Conditional check the error status code
    switch (error.response.status) {
      case 500:
        return true; // Retry request with response status code 500
      case 400:
        return true; // Retry request with response status code 400
      default:
        return false; // Do not retry the others
    }
  },
});

const url = process.env.REACT_APP_API_BASE_URL;
const tenantName = process.env.REACT_APP_TENANT_NAME;

export const handleResponse = (response) => {
  const data = response.data;
  if(!data){
    return response;
  }

  if (typeof data === 'string') {
      // Handle data when it's a string
      return JSON.parse(data);
  }
  return data;
}

export const getTrainingPrograms = async (userLanguage) => {
  
  const requestBody = {
    body: {
      response: null,
      language:userLanguage
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody,{
        headers: {
          'Content-Type': 'application/json',
          'x-api-key':'0883b905-0c53-4a52-9fa4-785a191cbc4d',
        }
      });
    
    if (response.data.success) {
      const trainingPrograms = response.data.data.data;
      
      return trainingPrograms;
    } else {
      console.log('Error:', response.data.message);
    }
  } catch (error) {
    console.error('API call failed:', error);
  }
};
export const getCourses = async (trainingProgramId) => {
  
  const requestBody = {
    body: {
      training_id:trainingProgramId
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody,{
        headers: {
          'Content-Type': 'application/json',
          'x-api-key':'dd3a34ce-af3d-4c3c-896c-e939e3be1ca2',
        }
      });
    
    if (response.data.success) {
      const courses = response.data;
      
      return courses;
    } else {
      console.log('Error:', response.data.message);
    }
  } catch (error) {
    console.error('API call failed:', error);
  }
};



export const getLessons = async (trainingProgramId) => {
  
  const requestBody = {
    body: {
      training_id:trainingProgramId
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'ceba7159-fab6-4246-b4df-c9382c706782',
      },
    });

    if (response.data.success) {
      const lessons = response.data; // Access lesson data
      return lessons;
    } else {
      console.error('Error:', response.data.message);
    }
  } catch (error) {
    console.error('API call failed:', error);
  }
};


export const GetAllDisplayPages = async () => {
 
  const requestBody = {
    body: {
      response: null,
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '1254d5de-5df2-47cd-ab59-90a9b70fd1c9', 
      },
    });

    if (response.data.success) {
      return response.data.data.data; 
    } else {
      console.error('Error:', response.data.message);
    }
  } catch (error) {
    console.error('API call failed:', error);
  }
};


export const createUserEvent = async (eventType, userEmail, trainingId, courseId = 0, lessonId = 0) => {
  
  
  const requestBody = {
    body: {
      request: {
        event_type: eventType,
        user_email: userEmail,
        training_id: trainingId,
        course_id: courseId,
        lesson_id: lessonId
      }
    },
    tenant_name: tenantName
  };
  console.log({
    event_type: eventType,
    user_email: userEmail,
    training_id: trainingId,
    course_id: courseId,
    lesson_id: lessonId
  },"createUserEvent")
  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6841d510-2c04-4495-a743-345730419cad', 
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in creating user event", error);
    throw error;
  }
};


			
export const getUsersAllTrainingProgramPercent = async (userEmail) => {
  
  const requestBody = {
    body: {user_email: userEmail},
    tenant_name: tenantName
  }

  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '356abdb0-c632-4287-9a6e-aa24f07b01f5', 
      },
    });
    
    if (response.data && response.data.success) {
      return response.data.data.data;
    } else {
      console.error("API failed: ", response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error fetching training programs: ", error);
    return null;
  }
};

export const getUserLessonsStatus = async (userEmail, trainingId, courseId) => {
  
  
  const requestBody = {
    body: {
      request: {
        user_email: userEmail,
        training_id: trainingId,
        course_id: courseId,
      },
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'c029e129-8a47-4d37-80fd-37a4525d37b0',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user lessons status:', error);
    throw error; 
  }
};

export const getToolkitData = async (toolkitName, language) => {

  const requestBody ={
    body: {
      toolkit: toolkitName,
      language: language
    },
    tenant_name: tenantName
  }
  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'e289b734-1498-4823-8a4e-fa2abc17fa22', 
      },
    });

    if (response.data.success && response.data.data) {
      return response.data.data.data;
    } else {
      console.error('Error fetching toolkit data:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('API call error:', error);
    return null;
  }
};

export const calculateToolkit1 = async (ownership) => {

  const requestBody = {
    body: {
      request: {
            lesson_header: "Lesson 1: Market Value",
            company: "Company",
            company_name: "Mack's Coffee Shop",
            shares: "Shares",
            shares_value: "10000",
            price_value: "100",
            shares_owned: "",
            ownership_value: ownership,
            total_amount: "Total Amount ($)",
            total_amount_value: "",
            market_value: "",
            symbol: "Symbol",
            baseline: "Baseline",
            symbol_name: "Macks",
            baseline_value: "F4Q22",
            price: "Price",
            ownership: "Ownership%",
            share_calculator: "Share Calculator",
            shares1: "Shares"
        }
    },
    tenant_name: tenantName
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '123d37d0-4e2b-4496-9a2f-bcaad32009d9', 
      },
    });

    if (response.data.success) {
      return response.data.data; 
    } else {
      throw new Error(response.data.message || "API call failed");
    }
  } catch (error) {
    console.error("Error in calculateToolkit1 API call:", error);
    throw error;
  }
};

export const calculateToolkit2 = async (salesValue, expensesValue) => {
  try {
   
    const requestBody = {
      body: {
        request: {
          lesson_header: "Lesson 1: Baseline Value",
          company: "Company",
          company_name: "Mack's Coffee Shop",
          market_v_price: "1000000",
          quarterly_c_flow_value: "",
          acfrr_value: "",
          cash_f_v_value: "",
          cash_f_v_p_s_value: "",
          implied_return_value: "",
          latest: "Latest Q",
          latest_value: "F3Q23",
          symbol: "Symbol",
          shares_outstanding: "Shares Outstanding",
          shares_out_value: "10000",
          market_price: "Market Price",
          market_p_value: "100",
          market_value: "Market Value",
          quarterly_income_statement: "Quarterly Income Statement",
          sales: "Sales",
          baseline: "Baseline",
          expenses: "Expenses",
          quarterly_cash_flow: "Quarterly Cas Flow",
          annual_cash_run_rate: "Annual Cash Flow Run Rate",
          cash_flow_value: "Cash Flow Value",
          cash_flow_value_per_share: "Cash Flow Value Per Share",
          implied_return: "Implied Return %",
          sales_value: salesValue,  
          expenses_value: expensesValue 
        }
      },
      tenant_name: tenantName
    };
    
  
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '55ffc0a0-db79-4b04-a44a-94a246a98c19', 
      },
    });

    
    return response.data.data;
  } catch (error) {
    console.error('Error in API call:', error);
    return null;
  }
};

export const calculateToolkit3 = async (unitsTwo,saleFour) => {

  const requestBody = {
    body: {
      request: {
        lesson_header: "Lesson 2: Sales",
        company_name: "Mack's Coffee Shop",
        year: "Year Ago",
        baseline: "Baseline",
        day: "Days",
        unit: "Units",
        sale: "Sales",
        units: "Units",
        sales: "Sales",
        avg: "Average Selling Price (ASP)",
        unit_one: "1",
        sale_one: "5",
        units_one: "1",
        sales_one: " ",
        per_day: "Per Day",
        day_two: "1",
        unit_two: "", 
        sale_two: "",
        units_two: unitsTwo, 
        sales_two: "",
        per_month: "Per Month",
        day_three: "30",
        unit_three: "",
        sale_three: "",
        units_three: "",
        sales_three: "",
        per_quarter: "Per Quarter",
        day_four: "90",
        unit_four: "",
        sale_four: saleFour, 
        units_four: "",
        sales_four: "",
        per_year: "Per Year",
        day_five: "360",
        unit_five: "",
        sale_five: "",
        units_five: "",
        sales_five: ""
      },
      result: null
    },
    tenant_name: tenantName
  };

  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '387a7f98-dc40-46a1-96c6-87f32c02288b', 
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error calculating Toolkit3:", error);
    throw error;
  }
};

export const calculateToolkit4 = async (unitSoldDaily) => {
 

  const requestBody = {
    "body": {
        "request": {
            "lesson_header": "Lesson 4: Cost-Of-Goods-Sold (COGS)",
            "daily": "Daily",
            "monthly": "Monthly",
            "quarterly": "Quarterly",
            "yearly": "Yearly",
            "units_sold": "Units Sold",
            "units_sold_daily": unitSoldDaily,
            "units_sold_monthly": "",
            "units_sold_quarterly": "",
            "units_sold_yearly": "",
            "cost_of_goods": "Cost-Of-Goods",
            "per_unit": "Per Unit",
            "per_month": "Per Month",
            "per_quarter": "Per Quarter",
            "per_year": "Per Year",
            "variable_costs": "Variable Costs",
            "var_cost_per_unit": "1.00",
            "var_cost_per_month": "",
            "var_cost_per_quarter": "",
            "var_cost_per_year": "",
            "fixed_costs": "Fixed Costs",
            "fix_cost_per_unit": "",
            "fix_cost_per_month": "30000",
            "fix_cost_per_quarter": "30000",
            "fix_cost_per_year": "30000",
            "total_costs": "Total Costs",
            "total_cost_per_unit": "",
            "total_cost_per_month": "",
            "total_cost_per_quarter": "",
            "total_cost_per_year": ""
        },
        "response": null
    },
    "tenant_name": tenantName,
}               

  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'edc6d29f-c078-43fc-8c07-7a28a158927b', 
      },
    });
    return response.data; 
  } catch (error) {
    console.error("Error making API call:", error);
    throw error; 
  }
};

export const calculateToolkit5 = async (comValue, expectedValue) => {

	const requestBody = {
    "body": {
        "request": {
            "lesson_header": "Lesson 4: Operating Cash Flow",
            "quarterly_income_statement": "Quarterly Income Statement",
            "baseline": "Baseline",
            "sales": "Sales",
            "sales_value": "45000",
            "cogs": "Cost-of-goods-sold (COGS)",
            "cogs_value": "39000",
            "gross_profit": "Gross Profit",
            "gross_profit_value": "6000",
            "sales_market": "Sales & Marketing (S&M)",
            "sales_market_value": "1500",
            "general": "General & Administrative (G&A)",
            "general_value": "1800",
            "operating": "Operating Profit",
            "operating_value": "2700",
            "tax": "Taxes (21%)",
            "depre": "+ Depreciation",
            "depre_value": "3000",
            "ocf": "Operating Cash Flow (OCF)",
            "ocf_value": "5133",
            "calculator": "Depreciation Calculator",
            "com": "Cost Of Machine",
            "com_value": comValue,
            "expected": "Expected Months Of Use",
            "expected_value":expectedValue,
            "monthly": "Monthly Depreciation",
            "quarterly": "Quarterly Depreciation (3mo)",
            "monthly_value": "",
            "quarterly_value": "",
            "tax_value": "567"
        },
        "calculate": null
    },
    "tenant_name": tenantName
}
  try {
    const response = await axios.post(url, requestBody,		{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'b0bfefa3-9bc5-4bc7-b1a4-e55c4d5634ff', 
      },
    });

    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to calculate toolkit data');
    }
  } catch (error) {
    console.error('Error in CalculateToolkit5:', error);
    throw error;
  }
};
export const calculateToolkit6 = async () => {

	const requestBody = {
    "body": {
        "calculate": null,
        "request": {
            "lesson_header": "LESSON 5: BASELINE VS. MARKET VALUE",
            "company": "COMPANY",
            "company_name": "Mack's Coffee Shop",
            "symbol": "SYMBOL",
            "symbol_name": "MACK",
            "shares_outstanding_one": "SHARES OUTSTANDING",
            "shares_outstanding_one_value": "10000",
            "market_price": "MKT PRICE",
            "market_price_value": "100",
            "quarterly_income_statement": "QUARTERLY INCOME STATEMENT",
            "baseline": "BASELINE",
            "sales": "Sales",
            "sales_value": "45000",
            "cogs": "Cost-of-goods-sold (COGS)",
            "cogs_value": "39000",
            "gross_profit": "Gross Profit",
            "gross_profit_value": "6000",
            "sales_market": "Sales & Marketing (S&M)",
            "sales_market_value": "1500",
            "general": "General & Administrative (G&A)",
            "general_value": "1800",
            "operating": "Operating Profit",
            "operating_value": "2700",
            "tax": "-Taxes (21%)",
            "tax_value": "567",
            "depre": "+ Depreciation",
            "depre_value": "3000",
            "ocf": "Operating Cash Flow (OCF)",
            "ocf_value": "5133",
            "annual_ocf": "Annual OCF Run Rate",
            "annual_ocf_value": "20532",
            "baseline_value": "Baseline Value",
            "baseline_v_value": "513300",
            "shares_outstanding": "Shares Outstanding",
            "shares_outstanding_value": "10000",
            "baseline_value_per_share": "Baseline Value Per Share",
            "baseline_per_share": "51"
        }
    },
    "tenant_name": tenantName
}
  try {
    const response = await axios.post(url, requestBody,		{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'e0216586-2785-4361-8d44-6eb33b712bde', 
      },
    });

    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to calculate toolkit data');
    }
  } catch (error) {
    console.error('Error in CalculateToolkit5:', error);
    throw error;
  }
};
		
export const calculateProgram2Toolkit3 = async (values) => {
  const requestBody = {
    "body": {
        "request": {
          year_over_forecast: values.year_over_forecast,
          sales_year: values.sales_year,
          sales_baseline: values.sales_baseline,
          year_over_baseline: values.year_over_baseline,
          sales_forecast: "",
        },
        "response": null
    },
    "tenant_name": tenantName
}
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'c5e3e0f5-02bd-4de3-bbca-651d3aef8664', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in calculateProgram2Toolkit3",error);
  throw error;
}


}
export const calculateProgram2Toolkit4 = async (yearOverForecastValue,cogsSalesForecastValue) => {
  const requestBody = {
    "body": {
        "request": {
            "sales_year": "36450",
            "sales_baseline": "45000",
            "sales_forecast": "",
            "year_over_baseline": "23.5",
            "year_over_forecast": yearOverForecastValue,
            "cog_sold_year": "37695",
            "cog_sold_baseline": "39000",
            "cog_sold_forecast": "",
            "cogs_sales_year": "103.4",
            "cogs_sales_baseline": "86.7",
            "cogs_sales_forecast": cogsSalesForecastValue,
            "gross_profit_year": "-1245",
            "gross_profit_baseline": "6000",
            "gross_profit_forecast": "",
            "gross_margin_year": "-3.4",
            "gross_margin_baseline": "13.3",
            "gross_margin_forecast": ""
        },
        "response": null
    },
    "tenant_name": tenantName
}
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': '8e7b6ea1-cae8-43a3-a171-4fcefb4249ae', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in calculateProgram2Toolkit4",error);
  throw error;
}


}
export const calculateProgram2Toolkit5 = async (yearOverForecastValue,cogsSalesForecastValue) => {
  const requestBody = {
    "body": {
        "request": {
            "sales_year": "36450",
            "sales_baseline": "45000",
            "sales_forecast": "",
            "year_over_baseline": "23.5",
            "year_over_forecast": yearOverForecastValue,
            "cog_sold_year": "37695",
            "cog_sold_baseline": "39000",
            "cog_sold_forecast": "",
            "cogs_sales_year": "103.4",
            "cogs_sales_baseline": "",
            "cogs_sales_forecast": cogsSalesForecastValue,
            "gross_profit_year": "-1245",
            "gross_profit_baseline": "6000",
            "gross_profit_forecast": "",
            "gross_margin_year": "-3.4",
            "gross_margin_baseline": "13.3",
            "gross_margin_forecast": "",
            "operating_profit_forecast": "",
            "operating_margin_forecast": "",
            "sales_market_baseline": "500",
            "sales_market_forecast": "",
            "sales_percent_baseline": "",
            "general_admin_baseline": "500",
            "general_admin_forecast": "",
            "general_percent_baseline": "",
            "enterprise_value_forecast": "",
            "cash_flow_value_forecast": "",
            "shares_outstanding_value_forecast": "10000",
            "annual_cash_flow_forecast": "",
            "quarterly_operating_cash_forecast": "",
            "tax_forecast": "",
            "depreciation": "3000",
            "implied_forecast": "",
            "market_px_value": "100"
        },
        "response": null
    },
    "tenant_name": tenantName
}
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': '56a9493d-e008-48db-80f7-862a78c1ff20', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in calculateProgram2Toolkit4",error);
  throw error;
}


}


export const forgotPasswordAPI = async (userEmail) => {
  const requestBody = {
    body: {
      request: {
        user_email: userEmail,
      },
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'bbe58a84-81d0-4a98-9901-9326a462622b', 
      },
    });
    return response.data; 
  } catch (error) {
    console.error('Error in forgot password API:', error);
    throw error; 
  }
};

export const getPrintTemplate = async (functionName) => {
  try {
      const requestBody = {
          body: {
              function_name: functionName
          },
          tenant_name: tenantName
      };

      const response = await axios.post(url, requestBody,{
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': '0897f637-cf0a-4077-836f-bbbb575a1821', 
        },
      });

      if (response.data.success && response.data.data.length > 0) {
          const displayFields = response.data.data[0].display_fields;

          if (displayFields && displayFields[0].printTemplate) {
              return displayFields[0].printTemplate;
          } else {
              throw new Error("printTemplate not found in display_fields.");
          }
      } else {
          throw new Error("No data found or request failed.");
      }
  } catch (error) {
      console.error("Error fetching print template:", error.message);
      return null;
  }
};