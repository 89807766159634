import React, { useState, useEffect } from 'react';
import { calculateToolkit2 } from "../../../../../api/programApi";
import PropTypes from 'prop-types';
import './tool.css';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {formatNumberWithCommasAndMillions,formatWithDollarAndCommas,stripFormatting} from "../../../../../utils/helper";


const Toolkit2 = ({ data }) => {
 
  
  const [calculatedData, setCalculatedData] = useState({});
  const [sales, setSales] = useState('0');
  const [expenses, setExpenses] = useState('0');

  
  useEffect(() => {
    const fetchInitialData = async () => {
      if (data) {
        const initialCalculatedData = await calculateToolkit2(sales,
        expenses);
        setCalculatedData({
          market_v_price: initialCalculatedData?.market_v_price,
          quarterly_c_flow_value: initialCalculatedData?.quarterly_c_flow_value,
          acfrr_value: initialCalculatedData?.acfrr_value,
          cash_f_v_value: initialCalculatedData?.cash_f_v_value,
          cash_f_v_p_s_value: initialCalculatedData?.cash_f_v_p_s_value,
          implied_return_value: initialCalculatedData?.implied_return_value,
        });
      }
    };
    fetchInitialData();
  }, [data]);


  const handleSalesChange = (e) => {
    setSales(e.target.value); 
  };

  const handleExpensesChange = (e) => {
    setExpenses(e.target.value); 
  };

  const handleBlur = async () => {
    setSales((prev) => formatWithDollarAndCommas(prev));
    setExpenses((prev) => formatWithDollarAndCommas(prev));
    const response = await calculateToolkit2(stripFormatting(sales),
    stripFormatting(expenses));
    
    setCalculatedData({
      market_v_price: response?.market_v_price,
      quarterly_c_flow_value: response?.quarterly_c_flow_value,
      acfrr_value: response?.acfrr_value,
      cash_f_v_value: response?.cash_f_v_value,
      cash_f_v_p_s_value: response?.cash_f_v_p_s_value,
      implied_return_value: response?.implied_return_value,
    });
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  const getField = (fieldName) => {
    const field = data?.data?.find((item) => item.field_name === fieldName);
    return field ? field : { field_value: '', info: '' };
  };

  const renderFieldWithInfo = (fieldName) => {
    const field = getField(fieldName);
    
    return (
      <div className="tool-column tool-label">
        
        {field.info && (
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id={`tooltip-${fieldName}`}>
                {field.info}
              </Tooltip>
            }
          >
            <i className="fas fa-info-circle me-2" style={{ cursor: 'pointer' }}></i>
          </OverlayTrigger>
        )}
        {field.field_value}
      </div>
    );
  };
  

  return (
    <div className="share-calculator">
      {/* First Header */}
      <h3 className="tool-header">
        <i className="fas fa-calculator me-2"></i>{getField("lesson_header").field_value}
      </h3>

      {/* Company Information */}
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("company").field_value}</div>
        <div className="tool-column tool-label">{getField("symbol").field_value}</div>
        <div className="tool-column tool-label">{getField("latest").field_value}</div>
      </div>
      <div className="tool-row">
        <div className="tool-column">{getField("company_name").field_value}</div>
        <div className="tool-column"></div>
        <div className="tool-column">{getField("latest_value").field_value}</div>
      </div>

      {/* Share Information */}
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("shares_outstanding").field_value}</div>
        <div className="tool-column tool-label">{getField("market_price").field_value}</div>
        <div className="tool-column tool-label">{getField("market_value ").field_value}</div>

      </div>
      <div className="tool-row">
        <div className="tool-column ">{formatNumberWithCommasAndMillions(Number(getField("shares_out_value").field_value))}</div>
        <div className="tool-column ">${formatNumberWithCommasAndMillions(Number(getField("market_p_value").field_value))}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(calculatedData?.market_v_price)}</div>
      </div>

      {/* Income Statement */}
      <div className="tool-row ">
        {renderFieldWithInfo("quarterly_income_statement")}
        {renderFieldWithInfo("baseline")}
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("sales").field_value}</div>
        <div className="tool-column">
          <input
            type="text"
            className="form-input"
            value={sales}
            onChange={handleSalesChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("expenses").field_value}</div>
        <div className="tool-column">
          <input
            type="text"
            className="form-input"
            value={expenses}
            onChange={handleExpensesChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      {/* Cash Flow Information */}
      <div className="tool-row">
        {renderFieldWithInfo("quarterly_cash_flow")}
        <div className="tool-column exchange-rate-result ">${formatNumberWithCommasAndMillions(calculatedData.quarterly_c_flow_value)}</div>
      </div>
      <div className="tool-row">
        {renderFieldWithInfo("annual_cash_run_rate")}
        <div className="tool-column exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData.acfrr_value)}</div>
      </div>
      <div className="tool-row">
        {renderFieldWithInfo("cash_flow_value")}
        <div className="tool-column exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData.cash_f_v_value)}</div>
      </div>
      <div className="tool-row">
        {renderFieldWithInfo("shares_outstanding")}
        <div className="tool-column ">{formatNumberWithCommasAndMillions(Number(getField("shares_out_value").field_value))}</div>
      </div>
      <div className="tool-row">
        {renderFieldWithInfo("cash_flow_value_per_share")}
        <div className="tool-column exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData.cash_f_v_p_s_value)}</div>
      </div>

      {/* Implied Return */}
      <div className="tool-row">
        {renderFieldWithInfo("implied_return")}
        <div className="tool-column exchange-rate-result">
          {calculatedData.implied_return_value}%
        </div>
      </div>
    </div>
  );
};

Toolkit2.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        field_name: PropTypes.string.isRequired,
        field_value: PropTypes.string.isRequired,
        info: PropTypes.string, // Optional info field
      })
    ).isRequired,
  }).isRequired,
};

export default Toolkit2;
