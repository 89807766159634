import React from "react";
import { Qute } from "../../imagepath";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './testimonial.css';
import { useTranslation } from "react-i18next";
const Testimonial = () => {
  
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <section className="custom-testimonial">
      <div className="custom-testimonial-container">
        <div className="custom-testimonial-quotes">
          <img src={Qute} alt="Quote" />
        </div>
        <div className="custom-testimonial-slider aos" data-aos="fade-up">
          <Slider {...sliderSettings}>
            <Card1 />
            <Card2 />
          </Slider>
        </div>
      </div>
    </section>
  );
};

const Card1 = () => {
  const { t } = useTranslation();
  return (
    <div className="custom-testimonial-content">
      <p>
     {t('testimonial1')}
      </p>
      <h3>David Liang, 24</h3>
    </div>
  );
};

const Card2 = () => {
  const { t } = useTranslation();
  return (
    <div className="custom-testimonial-content">
      <p>
      {t('testimonial2')}
      </p>
      <h3>Hamza Nadeem, 24</h3>
    </div>
  );
};

export default Testimonial;
