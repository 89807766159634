import React,{useState} from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import {logo,  RegisterImg1,
  RegisterImg2,
  RegisterImg3,
  RegisterImg4,
  RegisterImg5,
  RegisterImg6, } from "../../imagepath";
import FeatherIcon from "feather-icons-react";
import { Link ,useNavigate} from "react-router-dom";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setUserName,setUserEmail,setUserId,setSessionId} from "../../common/redux/userSlice";
import { useTranslation } from "react-i18next";
const Login = () => {
  const { t } = useTranslation();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [email, setEmail] = useState("");
  const [orgName] = useState(process.env.REACT_APP_TENANT_NAME);
  const [loading, setLoading] = useState(false);

  const campaign = localStorage.getItem("campaign") || "Default";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange =(e)=>{
      setPasswordInput(e.target.value);
  }
  const togglePassword =()=>{
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
  
    // Use FormData to create the request body
    const requestData = new FormData();
    requestData.append("email", email); // Assuming "email" should be the email
    requestData.append("password", passwordInput);
    requestData.append("org_name", orgName);
    
    dispatch(setUserEmail(email));

    try {
      let url = process.env.REACT_APP_API_BASE_URL
      url = url.replace("hit_api","fc_appengine/apps/login")
      const response = await axios.post(
        url,
        requestData, // Pass FormData object
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
          },
        }
      );
      if (response.data.success) {
        setLoading(false)
        const { Name,user_id,session_id } = response.data.data;
       
  
        // set name in redux state
        dispatch(setUserName(Name));
        dispatch(setUserId(user_id));
        dispatch(setSessionId(session_id));
       
        
    
  
    
        navigate("/student/student-dashboard");
      } else {
        alert("Login failed. Please check your credentials.");
      }
      
  
 
    } catch (error) {
      console.error("Login error:", error);
      // alert(`${error?.message}`);
      alert("Login failed. Please check your credentials.");
    }
  };

    var settings = {
        //autoWidth: true,
        items: 2,
        margin: 25,
        dots: true,
        nav: true,
        navText: [
          '<i className="fas fa-arrow-left"></i>',
          '<i className="fas fa-arrow-right"></i>',
        ],
    
        loop: true,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          768: {
            items: 1,
          },
          1170: {
            items: 1,
          },
        },
    };

    const campaignMessages = {
      "Pilot_A": t("welcomeMessage"),
      "Pilot_C": t("welcomeMessageCampaignC"),
      "Default": t("welcomeMessage"),
    };
  
    const welcomeMessageKey = campaignMessages[campaign];
  
      // Determine images based on campaign
      const images =
      campaign === "Pilot_C"
        ? [RegisterImg4, RegisterImg5, RegisterImg6]
        : [RegisterImg1, RegisterImg2, RegisterImg3];

  return (
    <>
      <div className="main-wrapper log-wrap">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
            <OwlCarousel 
            {...settings}
            className="owl-carousel login-slide owl-theme">
              {images.map((img, index) => (
                <div className="welcome-login" key={index}>
                  <div className="login-banner">
                    <img src={img} className="img-fluid" alt="Logo" />
                  </div>
                  <div className="mentor-course text-center">
                    <h2>{welcomeMessageKey}</h2>
                    <p>{t("studentGoal")}</p>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="w-100">
                  <div className="img-logo">
                    <img
                      src={logo}
                      className="img-fluid"
                      alt="Logo"
                    />
                    <div className="back-home">
                      <Link to="/home">{t('backToHomeAgain')}</Link>
                    </div>
                  </div>
                  <h1>{t('signIntoYourAccount')}</h1>
                  <form
                  //  action="/reactjs/instructor/instructor-dashboard"
                  onSubmit={handleSubmit}
                   >
                    <div className="input-block">
                      <label className="form-control-label">{t('email')}</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={t('enterEmailAddress')}
                        value={email}
                        onChange={handleEmailChange}
                        required
                      />
                    </div>
                    <div className="input-block">
                      <label className="form-control-label">{t('password')}</label>
                      <div className="pass-group">
                      <input type={passwordType} onChange={handlePasswordChange} value={passwordInput} name="password" className="form-control" placeholder={t('password')} />
                     <span className="toggle-password feather-eye" onClick={togglePassword}>
                     { passwordType==="password"? <FeatherIcon icon="eye"/>:<FeatherIcon icon="eye-off"/> }
                     </span>
                      </div>
                    </div>
                    <div className="forgot">
                      <span>
                        <Link className="forgot-link" to="/forgot-password">
                        {t('forgot_password')}
                        </Link>
                      </span>
                    </div>
                    <div className="remember-me">
                      <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                        {" "}
                        {t('rememberMe')}
                        <input type="checkbox" name="radio" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="d-grid">
                      <button

                        // to="/student/student-dashboard"
                        className="btn btn-start"
                        type="submit"
                      >{
                        loading && (
                          <><i className="fa-solid fa-spinner fa-spin"></i>&nbsp;&nbsp;</>
                        )
                      }
                       {t('signin')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="google-bg text-center">
                {/* <span>
                  <Link to="#">Or sign in with</Link>
                </span>
                <div className="sign-google">
                  <ul>
                    <li>
                      <Link to="#">
                        <img
                          src={NetIcon1}
                          className="img-fluid"
                          alt="Logo"
                        />{" "}
                        Sign In using Google
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img
                          src={NetIcon2}
                          className="img-fluid"
                          alt="Logo"
                        />
                        Sign In using Facebook
                      </Link>
                    </li>
                  </ul>
                </div> */}
                <p className="mb-0">
                {t('newUser')} ? <Link to="/register">{t('createAnAccount')}</Link>
                </p>
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
