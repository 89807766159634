import React,{useState} from "react";
import StickyBox from "react-sticky-box";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { updateStudentDetails, getStudentDetails } from "../../../api/studentApi";
import useLogout from "../../../hooks/useLogout"; 
import { useSelector,useDispatch } from "react-redux";
import { setUserAvatar } from "../../../components/common/redux/userSlice";
import { useTranslation } from "react-i18next";
import {User16} from "../../imagepath";

// eslint-disable-next-line react/prop-types
export default function StudentSidebar() {
  const { t } = useTranslation();
  const { handleLogoutAndRedirect } = useLogout();
  const location = useLocation();
 
  // Get user details from Redux
  const userName = useSelector((state) => state.userSlice.name);
  const avatar = useSelector((state) => state.userSlice.avatar);
  const email = useSelector((state) => state.userSlice.email);
 
  // const [loading, setLoading] = useState(false); 
  const [uploading, setUploading] = useState(false); 


  const dispatch = useDispatch();

 
  

  const handleProfilePictureClick = () => {
    document.getElementById("avatarInput").click(); 
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      setUploading(true); 
      reader.onloadend = async () => {
        const base64Image = reader.result; 
        const fileName = file.name;
       
  
        
       
        if (email) {
          const updateData = {
            email,
            avatar: {
              files: [base64Image],   
              fileNames: [fileName],  
            },
            status: "Active"
          };
  
          try {
            
            await updateStudentDetails(updateData);
  
           
            const studentDetailsResponse = await getStudentDetails(email);
            // Fetch the S3 link from the avatar field
            const updatedAvatar = studentDetailsResponse?.data?.data[0]?.avatar[0] || null;
           
            
            // Set the avatar state to the S3 link
            dispatch(setUserAvatar(updatedAvatar));
           
          } catch (error) {
            console.error("Error updating avatar:", error);
          }
          finally {
            setUploading(false); // Stop uploading loader
          }
        }
      };
      reader.readAsDataURL(file); // Convert file to Base64
    }
  };
  

  return (
    <div className="col-xl-3 col-lg-3 theiaStickySidebar only-display-web">
      <StickyBox offsetTop={20} offsetBottom={20}>
        <div className="settings-widget dash-profile">
          <div className="settings-menu">
            <div className="profile-bg">
              <div className="profile-img">
      
                <Link to="#"
                 onClick={handleProfilePictureClick}
                 >
           
                  <img src={avatar || User16} alt="Profile" /> 
                </Link>
                <input
                  type="file"
                  id="avatarInput"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleFileChange}
                  disabled={uploading} 
                />
                {uploading && <p>{t('uploading')}...</p>} 
              </div>
            </div>
            <div className="profile-group">
              <div className="profile-name text-center">
                <h4>
                  <Link to="/student/student-profile">{userName}</Link>
                </h4>
                <p>{t('student')}</p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="settings-widget account-settings">
          <div className="settings-menu">
            <h3>{t('dashboard')}</h3>
            <ul>
              <li className={`nav-item ${location.pathname === '/student/student-dashboard' ? 'active' : ''}`}>

                <Link to="/student/student-dashboard" className="nav-link">
                  <i className="bx bxs-tachometer" />
                  {t('dashboard')}
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/student/student-profile' ? 'active' : ''}`}>

                <Link to="/student/student-profile" className="nav-link">
                  <i className="bx bxs-user" />
                  {t('myProfile')}
                </Link>
              </li>
              {/* <li className={`nav-item ${location.pathname === '/student/student-courses' ? 'active' : ''}`}>
                <Link
                  to="/student/student-courses"
                  className="nav-link"
                >
                  <i className="bx bxs-graduation" />
                  Enrolled Courses
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/student/student-wishlist' || location.pathname === '/student/student-quiz-details' ? 'active' : ''}`}>

                <Link to="/student/student-wishlist" className="nav-link">
                  <i className="bx bxs-heart" />
                  Wishlist
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/student/student-reviews' ? 'active' : ''}`}>

                <Link to="/student/student-reviews" className="nav-link">
                  <i className="bx bxs-star" />
                  Reviews
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/student/student-quiz' ? 'active' : ''}`}>

                <Link to="/student/student-quiz" className="nav-link">
                  <i className="bx bxs-shapes" />
                  My Quiz Attempts
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/student/student-order-history' ? 'active' : ''}`}>
                <Link to="/student/student-order-history" className="nav-link">
                  <i className="bx bxs-cart" />
                  Order History
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/student/student-qa' ? 'active' : ''}`}>

                <Link to="/student/student-qa" className="nav-link">
                  <i className="bx bxs-bookmark-alt" />
                  Question &amp; Answer
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/student/student-referral' ? 'active' : ''}`}>

                <Link to="/student/student-referral" className="nav-link">
                  <i className="bx bxs-user-plus" />
                  Referrals
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/student/student-messages' ? 'active' : ''}`}>
                <Link to="/student/student-messages" className="nav-link">
                  <i className="bx bxs-chat" />
                  Messages
                </Link>
              </li>

              <li className={`nav-item ${location.pathname === '/student/student-ticket' ? 'active' : ''}`}>

                <Link to="/student/student-ticket" className="nav-link">
                  <i className="bx bxs-coupon" />
                  Support Tickets
                </Link>
              </li> */}
            </ul>
            <h3>{t('accountSettings')}</h3>
            <ul>
              <li className={`nav-item ${location.pathname === '/student/student-setting' || location.pathname === '/student/student-change-password' || location.pathname === '/student/student-social-profile' || location.pathname === '/student/student-linked-accounts' || location.pathname === '/student/student-notification' ? 'active' : ''}`}>
                <Link to="/student/student-setting" className="nav-link ">
                  <i className="bx bxs-cog" />
                  {t('settings')}
                </Link>
              </li>
              <li className="nav-item">
              <Link to="/home" className="nav-link" onClick={(e) => {
                    e.preventDefault();
                    handleLogoutAndRedirect(); 
                  }} >
                  <i className="bx bxs-log-out" />
                  {t('logout')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </StickyBox>
    </div>
  );
}
