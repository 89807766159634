import React,{useState} from "react";
import { Country, City } from "country-state-city";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { 
  // LoginImg,
  logo,
  RegisterImg1,
  RegisterImg2,
  RegisterImg3,
  RegisterImg4,
  RegisterImg5,
  RegisterImg6,
} from "../../imagepath";
import {registerStudent} from "../../../api/studentApi";
import { useTranslation } from "react-i18next";


// const hasNumber = (value) => {
//   return new RegExp(/[0-9]/).test(value);
// };
// const hasMixed = (value) => {
//   return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
// };
// const hasSpecial = (value) => {
//   return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
// };

// const strengthColor = (count) => {
//   if (count < 1) return "poor";
//   if (count < 2) return "weak";
//   if (count < 3) return "strong";
//   if (count < 4) return "heavy";
// };

const Register = () => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false)
  const campaign = localStorage.getItem("campaign") || "Default";

  const [student, setStudent] = useState({
    
    first_name: "",
    last_name: "",
    email: "",
    contact_no: "",
    country_code: "+1",
    language: "Spanish",
    university: "",
    age: "",
    city: "",
    country: "",
    campaign,
    agreement: false,
    
  });

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const [cities, setCities] = useState([]);

  const handleCountryChange = (e) => {
    const selectedIsoCode = e.target.value;
    const selectedCountry = Country.getAllCountries().find(
      (country) => country.isoCode === selectedIsoCode
    );
    setStudent({ ...student, country: selectedCountry.name, city: "" });
    setCities(City.getCitiesOfCountry(selectedIsoCode));
  };
  const getIsoCodeFromName = (countryName) => {
    const country = Country.getAllCountries().find(
      (country) => country.name === countryName
    );
    return country ? country.isoCode : "";
  };
  
  



  const campaignMessages = {
    "Pilot_A": t("welcomeMessage"),
    "Pilot_C": t("welcomeMessageCampaignC"),
    "Default": t("welcomeMessage"),
  };

  const welcomeMessageKey = campaignMessages[campaign];

    // Determine images based on campaign
    const images =
    campaign === "Pilot_C"
      ? [RegisterImg4, RegisterImg5, RegisterImg6]
      : [RegisterImg1, RegisterImg2, RegisterImg3];

      const handleChange = (e) => {
        setStudent({
          ...student,
          [e.target.name]: e.target.value,
        });
      };

  const handleAgreementCheck = (e) => {
    setStudent({
      ...student,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = async (e) => {
    setSubmitting(true)
    e.preventDefault();
    if(submitting){
      return;
    }
    if(!student.agreement){
      setError(t("acceptAgreement"));
      setSubmitting(false);
      return;
    }

     // Concatenate country code with contact number
     const fullContactNo = `${student.country_code}${student.contact_no}`;

     const updatedStudent = {
      ...student,
      contact_no: fullContactNo,
      age: parseInt(student.age, 10),
    };
    
    try {
      const result = await registerStudent(updatedStudent);
      setSubmitting(false);
      let response = result?.data;
      if(response && typeof response == "string"){
        response = JSON.parse(response);
      }
    
      if(response && response.status && response.status !== "200"){
        setError(response.message);
        return;
      }
      if(response && typeof response.success !== "undefined" && !response.success){
        setError(response.message);
        return;
      }
      setResponse(result);
    } catch (err) {
      setError(err.response ? err.response.data : 'An unexpected error occurred');
      setSubmitting(false);
    }
  };

  // const [eye, seteye] = useState(true);
  // const [password, setPassword] = useState("");
  // const [validationError, setValidationError] = useState("");
  // const [strength, setStrength] = useState("");
  // const [pwdError, setPwdError] = useState("Use 8 or more characters with a mix of letters, numbers & symbols.")

  // const onEyeClick = () => {
  //   seteye(!eye);
  // };

  // const handlePasswordChange = (event) => {
  //   const newPassword = event.target.value;
  //   setPassword(newPassword);
  //   validatePassword(newPassword);
  // };

  // const validatePassword = (value) => {
  //   if (!value) {
  //     setValidationError(1);
  //   } else if (value.length < 8) {
  //     setValidationError(2);
  //   } else if (!/[0-9]/.test(value)) {
  //     setValidationError(3);
  //   } else if (!/[!@#$%^&*()]/.test(value)) {
  //     setValidationError(4);
  //   } else {
  //     setValidationError(5);
  //   }
  // };

  // const messages = () => {
  //   if (validationError == 1) {
  //     return "";
  //   } else if (validationError == 2) {
  //     return (
  //       <span
  //         id="poor"
  //         className="active"
  //         style={{ fontSize: 12, color: "#DC3545" }}
  //       >
  //         😠 Weak. Must contain at least 8 characters
  //       </span>
  //     );
  //   } else if (validationError == 3) {
  //     return (
  //       <span
  //         id="weak"
  //         className="active"
  //         style={{ fontSize: 12, color: "#FFC107" }}
  //       >
  //         😲 Average. Must contain at least 1 letter or number
  //       </span>
  //     );
  //   } else if (validationError == 4) {
  //     return (
  //       <span
  //         id="strong"
  //         className="active"
  //         style={{ fontSize: 12, color: "#0D6EFD" }}
  //       >
  //         🙂 Almost. Must contain special symbol
  //       </span>
  //     );
  //   } else if (validationError == 5) {
  //     return (
  //       <span
  //         id="heavy"
  //         className="active"
  //         style={{ fontSize: 12, color: "#4BB543" }}
  //       >
  //         😊 Awesome! You have a secure password.
  //       </span>
  //     );
  //   }
  // };

  // const strengthIndicator = (value) => {
  //   let strengths = 0;

  //   if (value.length >= 8) strengths = 1;
  //   if (hasNumber(value) && value.length >= 8) strengths = 2;
  //   if (hasSpecial(value) && value.length >= 8 && hasNumber(value))
  //     strengths = 3;
  //   if (
  //     hasMixed(value) &&
  //     hasSpecial(value) &&
  //     value.length >= 8 &&
  //     hasNumber(value)
  //   )
  //     strengths = 3;
  //   return strengths;
  // };

  var settings = {
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };

  // useEffect(() => {
  //   if (password) {
  //     if (password !== "") {
  //       let strength = strengthIndicator(password);
  //       let color = strengthColor(strength);
  //       setStrength(color);
  //     } else {
  //       setStrength("");
  //     }
  //   }
  // }, [password]);



  return (
    <>
      <div className="main-wrapper log-wrap">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
          <OwlCarousel {...settings} className="owl-carousel login-slide owl-theme">
              {images.map((img, index) => (
                <div className="welcome-login" key={index}>
                  <div className="login-banner">
                    <img src={img} className="img-fluid" alt="Logo" />
                  </div>
                  <div className="mentor-course text-center">
                    <h2>{welcomeMessageKey}</h2>
                    <p>{t("studentGoal")}</p>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
          {/* /Login Banner */}
          
          {response ?  <div className="main-wrapper log-wrap">
        <div className="row">
          {/* Thank You Message */}
          <div className="col-md-6 offset-md-3 login-wrap-bg">
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="img-logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                  <div className="back-home">
                    <Link to="/home">{t('backToHomeAgain')}</Link>
                  </div>
                </div>
                <h1>{t('thankYou')}</h1>
                <p>{t('credentialsSent')}</p>
                <div className="d-grid">
                  <Link to="/login" className="btn btn-primary btn-start">
                  {t('login')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* /Thank You Message */}
        </div>
      </div> :<div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="img-logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                  <div className="back-home">
                    <Link to="/home">{t('backToHome')}</Link>
                  </div>
                </div>
                <h1>{t('signup')}</h1>
                <form 
                // action="/reactjs/login"
                 onSubmit={handleSubmit}>

               

                  <div className="input-block">
                    <label className="form-control-label">{t('firstName')}</label>
                    <input
                      type="text"
                      name="first_name"
                      className="form-control"
                      placeholder={t('enterFirstName')}
                      value={student.first_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="input-block">
                    <label className="form-control-label">{t('lastName')}</label>
                    <input
                      type="text"
                      name="last_name"
                      className="form-control"
                      placeholder={t('enterLastName')}
                      value={student.last_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="input-block">
                    <label className="form-control-label">{t('email')}</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder={t('enterEmailAddress')}
                      value={student.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                   {/* University Field */}
                  <div className="input-block">
                    <label className="form-control-label">{t("organizationUniversity")}</label>
                    <input
                      type="text"
                      name="university"
                      className="form-control"
                      placeholder={t("enterUniversity")}
                      value={student.university}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {/* Age Field */}
                  <div className="input-block">
                    <label className="form-control-label">{t("age")}</label>
                    <input
                      type="number"
                      name="age"
                      className="form-control"
                      placeholder={t("enterAge")}
                      value={student.age}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {/* Country Field */}
                    <div className="input-block">
                      <label className="form-control-label">{t("country")}</label>
                      {/* Country Dropdown */}
                      <select 
                      name="country" 
                      className="form-control"
                      value={getIsoCodeFromName(student.country)} // Convert name to ISO code
                      onChange={handleCountryChange}
                    >
                      <option value="">{t("selectCountry")}</option>
                      {Country.getAllCountries().map((country) => (
                        <option key={country.isoCode} value={country.isoCode}>
                          {country.name}
                        </option>
                      ))}
                    </select>

                    </div>

                  {/* City Field */}
                    <div className="input-block">
                    <label className="form-control-label">{t("city")}</label>
                    {/* City Dropdown */}
                    <select 
                    name="city" 
                    className="form-control"
                    value={student.city} 
                    onChange={handleChange}
                    required
                    >
                      <option value="">{t("selectCity")}</option>
                      {cities.map((city) => (
                        <option key={city.name} value={city.name}>
                          {city.name}
                        </option>
                      ))}
                      </select>
                    </div>


                  <div className="input-block">
                    <label className="form-control-label">{t('contactNumber')}</label>
                    <div className="d-flex">
                        {/* Country code select */}
                        <select
                          name="country_code"
                          className="form-control"
                          value={student.country_code}
                          onChange={handleChange}
                        >
                         
                        <option value="+44" selected>UK (+44)</option>
                        <option value="+1">USA (+1)</option>
                        <option value="+213">Algeria (+213)</option>
                        <option value="+376">Andorra (+376)</option>
                        <option value="+244">Angola (+244)</option>
                        <option value="+1264">Anguilla (+1264)</option>
                        <option value="+1268">Antigua &amp; Barbuda (+1268)</option>
                        <option value="+54">Argentina (+54)</option>
                        <option value="+374">Armenia (+374)</option>
                        <option value="+297">Aruba (+297)</option>
                        <option value="+61">Australia (+61)</option>
                        <option value="+43">Austria (+43)</option>
                        <option value="+994">Azerbaijan (+994)</option>
                        <option value="+1242">Bahamas (+1242)</option>
                        <option value="+973">Bahrain (+973)</option>
                        <option value="+880">Bangladesh (+880)</option>
                        <option value="+1246">Barbados (+1246)</option>
                        <option value="+375">Belarus (+375)</option>
                        <option value="+32">Belgium (+32)</option>
                        <option value="+501">Belize (+501)</option>
                        <option value="+229">Benin (+229)</option>
                        <option value="+1441">Bermuda (+1441)</option>
                        <option value="+975">Bhutan (+975)</option>
                        <option value="+591">Bolivia (+591)</option>
                        <option value="+387">Bosnia Herzegovina (+387)</option>
                        <option value="+267">Botswana (+267)</option>
                        <option value="+55">Brazil (+55)</option>
                        <option value="+673">Brunei (+673)</option>
                        <option value="+359">Bulgaria (+359)</option>
                        <option value="+226">Burkina Faso (+226)</option>
                        <option value="+257">Burundi (+257)</option>
                        <option value="+855">Cambodia (+855)</option>
                        <option value="+237">Cameroon (+237)</option>
                        <option value="+1">Canada (+1)</option>
                        <option value="+238">Cape Verde Islands (+238)</option>
                        <option value="+1345">Cayman Islands (+1345)</option>
                        <option value="+236">Central African Republic (+236)</option>
                        <option value="+56">Chile (+56)</option>
                        <option value="+86">China (+86)</option>
                        <option value="+57">Colombia (+57)</option>
                        <option value="+269">Comoros (+269)</option>
                        <option value="+242">Congo (+242)</option>
                        <option value="+682">Cook Islands (+682)</option>
                        <option value="+506">Costa Rica (+506)</option>
                        <option value="+385">Croatia (+385)</option>
                        <option value="+53">Cuba (+53)</option>
                        <option value="+90392">Cyprus North (+90392)</option>
                        <option value="+357">Cyprus South (+357)</option>
                        <option value="+42">Czech Republic (+42)</option>
                        <option value="+45">Denmark (+45)</option>
                        <option value="+253">Djibouti (+253)</option>
                        <option value="+1809">Dominica (+1809)</option>
                        <option value="+1809">Dominican Republic (+1809)</option>
                        <option value="+593">Ecuador (+593)</option>
                        <option value="+20">Egypt (+20)</option>
                        <option value="+503">El Salvador (+503)</option>
                        <option value="+240">Equatorial Guinea (+240)</option>
                        <option value="+291">Eritrea (+291)</option>
                        <option value="+372">Estonia (+372)</option>
                        <option value="+251">Ethiopia (+251)</option>
                        <option value="+500">Falkland Islands (+500)</option>
                        <option value="+298">Faroe Islands (+298)</option>
                        <option value="+679">Fiji (+679)</option>
                        <option value="+358">Finland (+358)</option>
                        <option value="+33">France (+33)</option>
                        <option value="+594">French Guiana (+594)</option>
                        <option value="+689">French Polynesia (+689)</option>
                        <option value="+241">Gabon (+241)</option>
                        <option value="+220">Gambia (+220)</option>
                        <option value="+7880">Georgia (+7880)</option>
                        <option value="+49">Germany (+49)</option>
                        <option value="+233">Ghana (+233)</option>
                        <option value="+350">Gibraltar (+350)</option>
                        <option value="+30">Greece (+30)</option>
                        <option value="+299">Greenland (+299)</option>
                        <option value="+1473">Grenada (+1473)</option>
                        <option value="+590">Guadeloupe (+590)</option>
                        <option value="+671">Guam (+671)</option>
                        <option value="+502">Guatemala (+502)</option>
                        <option value="+224">Guinea (+224)</option>
                        <option value="+245">Guinea - Bissau (+245)</option>
                        <option value="+592">Guyana (+592)</option>
                        <option value="+509">Haiti (+509)</option>
                        <option value="+504">Honduras (+504)</option>
                        <option value="+852">Hong Kong (+852)</option>
                        <option value="+36">Hungary (+36)</option>
                        <option value="+354">Iceland (+354)</option>
                        <option value="+91">India (+91)</option>
                        <option value="+62">Indonesia (+62)</option>
                        <option value="+98">Iran (+98)</option>
                        <option value="+964">Iraq (+964)</option>
                        <option value="+353">Ireland (+353)</option>
                        <option value="+972">Israel (+972)</option>
                        <option value="+39">Italy (+39)</option>
                        <option value="+1876">Jamaica (+1876)</option>
                        <option value="+81">Japan (+81)</option>
                        <option value="+962">Jordan (+962)</option>
                        <option value="+7">Kazakhstan (+7)</option>
                        <option value="+254">Kenya (+254)</option>
                        <option value="+686">Kiribati (+686)</option>
                        <option value="+850">Korea North (+850)</option>
                        <option value="+82">Korea South (+82)</option>
                        <option value="+965">Kuwait (+965)</option>
                        <option value="+996">Kyrgyzstan (+996)</option>
                        <option value="+856">Laos (+856)</option>
                        <option value="+371">Latvia (+371)</option>
                        <option value="+961">Lebanon (+961)</option>
                        <option value="+266">Lesotho (+266)</option>
                        <option value="+231">Liberia (+231)</option>
                        <option value="+218">Libya (+218)</option>
                        <option value="+417">Liechtenstein (+417)</option>
                        <option value="+370">Lithuania (+370)</option>
                        <option value="+352">Luxembourg (+352)</option>
                        <option value="+853">Macao (+853)</option>
                        <option value="+389">Macedonia (+389)</option>
                        <option value="+261">Madagascar (+261)</option>
                        <option value="+265">Malawi (+265)</option>
                        <option value="+60">Malaysia (+60)</option>
                        <option value="+960">Maldives (+960)</option>
                        <option value="+223">Mali (+223)</option>
                        <option value="+356">Malta (+356)</option>
                        <option value="+692">Marshall Islands (+692)</option>
                        <option value="+596">Martinique (+596)</option>
                        <option value="+222">Mauritania (+222)</option>
                        <option value="+269">Mayotte (+269)</option>
                        <option value="+52">Mexico (+52)</option>
                        <option value="+691">Micronesia (+691)</option>
                        <option value="+373">Moldova (+373)</option>
                        <option value="+377">Monaco (+377)</option>
                        <option value="+976">Mongolia (+976)</option>
                        <option value="+1664">Montserrat (+1664)</option>
                        <option value="+212">Morocco (+212)</option>
                        <option value="+258">Mozambique (+258)</option>
                        <option value="+95">Myanmar (+95)</option>
                        <option value="+264">Namibia (+264)</option>
                        <option value="+674">Nauru (+674)</option>
                        <option value="+977">Nepal (+977)</option>
                        <option value="+31">Netherlands (+31)</option>
                        <option value="+687">New Caledonia (+687)</option>
                        <option value="+64">New Zealand (+64)</option>
                        <option value="+505">Nicaragua (+505)</option>
                        <option value="+227">Niger (+227)</option>
                        <option value="+234">Nigeria (+234)</option>
                        <option value="+683">Niue (+683)</option>
                        <option value="+672">Norfolk Islands (+672)</option>
                        <option value="+670">Northern Marianas (+670)</option>
                        <option value="+47">Norway (+47)</option>
                        <option value="+968">Oman (+968)</option>
                        <option value="+680">Palau (+680)</option>
                        <option value="+507">Panama (+507)</option>
                        <option value="+675">Papua New Guinea (+675)</option>
                        <option value="+595">Paraguay (+595)</option>
                        <option value="+51">Peru (+51)</option>
                        <option value="+63">Philippines (+63)</option>
                        <option value="+48">Poland (+48)</option>
                        <option value="+351">Portugal (+351)</option>
                        <option value="+1787">Puerto Rico (+1787)</option>
                        <option value="+974">Qatar (+974)</option>
                        <option value="+262">Reunion (+262)</option>
                        <option value="+40">Romania (+40)</option>
                        <option value="+7">Russia (+7)</option>
                        <option value="+250">Rwanda (+250)</option>
                        <option value="+378">San Marino (+378)</option>
                        <option value="+239">Sao Tome &amp; Principe (+239)</option>
                        <option value="+966">Saudi Arabia (+966)</option>
                        <option value="+221">Senegal (+221)</option>
                        <option value="+381">Serbia (+381)</option>
                        <option value="+248">Seychelles (+248)</option>
                        <option value="+232">Sierra Leone (+232)</option>
                        <option value="+65">Singapore (+65)</option>
                        <option value="+421">Slovak Republic (+421)</option>
                        <option value="+386">Slovenia (+386)</option>
                        <option value="+677">Solomon Islands (+677)</option>
                        <option value="+252">Somalia (+252)</option>
                        <option value="+27">South Africa (+27)</option>
                        <option value="+34">Spain (+34)</option>
                        <option value="+94">Sri Lanka (+94)</option>
                        <option value="+290">St. Helena (+290)</option>
                        <option value="+1869">St. Kitts (+1869)</option>
                        <option value="+1758">St. Lucia (+1758)</option>
                        <option value="+249">Sudan (+249)</option>
                        <option value="+597">Suriname (+597)</option>
                        <option value="+268">Swaziland (+268)</option>
                        <option value="+46">Sweden (+46)</option>
                        <option value="+41">Switzerland (+41)</option>
                        <option value="+963">Syria (+963)</option>
                        <option value="+886">Taiwan (+886)</option>
                        <option value="+7">Tajikstan (+7)</option>
                        <option value="+66">Thailand (+66)</option>
                        <option value="+228">Togo (+228)</option>
                        <option value="+676">Tonga (+676)</option>
                        <option value="+1868">Trinidad &amp; Tobago (+1868)</option>
                        <option value="+216">Tunisia (+216)</option>
                        <option value="+90">Turkey (+90)</option>
                        <option value="+993">Turkmenistan (+993)</option>
                        <option value="+1649">Turks &amp; Caicos Islands (+1649)</option>
                        <option value="+688">Tuvalu (+688)</option>
                        <option value="+256">Uganda (+256)</option>
                        <option value="+380">Ukraine (+380)</option>
                        <option value="+971">United Arab Emirates (+971)</option>
                        <option value="+598">Uruguay (+598)</option>
                        <option value="+678">Vanuatu (+678)</option>
                        <option value="+379">Vatican City (+379)</option>
                        <option value="+58">Venezuela (+58)</option>
                        <option value="+84">Vietnam (+84)</option>
                        <option value="+1284">Virgin Islands - British (+1284)</option>
                        <option value="+1340">Virgin Islands - US (+1340)</option>
                        <option value="+681">Wallis &amp; Futuna (+681)</option>
                        <option value="+969">Yemen (North)(+969)</option>
                        <option value="+967">Yemen (South)(+967)</option>
                        <option value="+260">Zambia (+260)</option>
                        <option value="+263">Zimbabwe (+263)</option>                 

                         
                        </select>

                        {/* Contact number input */}
                        <input
                          type="text"
                          name="contact_no"
                          className="form-control ms-2"
                          placeholder={t("enterContactNumber")}
                          value={student.contact_no}
                          onChange={handleChange}
                          required
                        />
                        <input
                          type="hidden"
                          name="campaign"
                          value={student.campaign}
                        />
                      </div>
                  </div>
                  <div className="input-block">
                      <label className="form-control-label">{t('language')}</label>
                      <select
                        name="language"
                        className="form-control"
                        value={student.language}
                        onChange={handleChange}
                      >
                        <option value="Spanish">{t('spanish')}</option>
                        <option value="English">{t('english')}</option>
                      </select>
                  </div>
                  {/* <div className="input-block">
                    <label className="form-control-label">Password</label>
                    <div className="pass-group" id="passwordInput">
                      <input
                        className="form-control pass-input"
                        placeholder="Enter your password"
                        type={eye ? "password" : "text"}
                        onChange={handlePasswordChange}
                      />
                      
                      <span
                        onClick={onEyeClick}
                        className={`fa toggle-password feather-eye" ${
                          eye ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                      <span className="toggle-password feather-eye"></span>
                      <span className="pass-checked">
                        <i className="feather-check"></i>
                      </span>
                    </div>
                    <div
                      id="passwordStrength"
                      style={{ display: "flex" }}
                      className={`password-strength ${
                        strength === "poor"
                          ? "poor-active"
                          : strength === "weak"
                          ? "avg-active"
                          : strength === "strong"
                          ? "strong-active"
                          : strength === "heavy"
                          ? "heavy-active"
                          : ""
                      }`}
                    >
                      <span id="poor" className="active"></span>
                      <span id="weak" className="active"></span>
                      <span id="strong" className="active"></span>
                      <span id="heavy" className="active"></span>
                    </div>
                    <div id="passwordInfo">{messages()}</div>
                  </div> */}

                  <div className="form-check remember-me mb-3">
                    <label className="form-check-label mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="agreement"
                        onChange={handleAgreementCheck}
                      />
                      {
                        (student.campaign && student.campaign === "Pilot_A") ?
                        (
                          <Link to="/term-condition" target="_blank">{t('termsAndConditions')}</Link>
                        ):
                        (
                          <Link to="/term-condition" target="_blank">{t('iAgreeTo')}&nbsp;{t('termsOfService')}</Link>
                        )
                      }
                    </label>
                  </div>
                  <div className="form-check remember-me mb-3 px-0">
                    <label className="form-check-label mb-0">
                      <i className="fa-solid fa-gavel"></i>&nbsp;&nbsp;&nbsp;
                      <Link to="/disclaimer" target="_blank">{t('legalNotice')}</Link>
                    </label>
                  </div>
                  <div className="d-grid">
                    {response && (
                      <div>
                        <h3>{t('registrationSuccess')}</h3>
                        <pre>{JSON.stringify(response, null, 2)}</pre>
                      </div>
                    )}
                    {error && (
                      <div className="my-2 px-2" style={{borderLeft: '3px solid red'}}>
                        <label style={{color: 'red'}}>{t('errorOccurred')}</label>
                        <pre>{JSON.stringify(error, null, 2)}</pre>
                      </div>
                    )}
                    <button type="submit" className="btn btn-primary btn-start">
                      {submitting && (
                        <><i className="fa-solid fa-spinner fa-spin-pulse"></i>&nbsp;&nbsp;</>
                      )}
                      {t('register')}</button>
                  </div>
                </form>
              </div>
              {/* <div className="google-bg text-center">
                <span>
                  <Link to="#">Or sign in with</Link>
                </span>
                <div className="sign-google">
                  <ul>
                    <li>
                      <Link to="#">
                        <img src={NetIcon1} className="img-fluid" alt="Logo" />
                        Sign In using Google
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={NetIcon2} className="img-fluid" alt="Logo" />
                        Sign In using Facebook
                      </Link>
                    </li>
                  </ul>
                </div>
                <p className="mb-0">
                  Already have an account? <Link to="/login">Sign in</Link>
                </p>
              </div> */}
            </div>
            {/* /Login */}
          </div> }
 
 
        </div>
      </div>
    </>
  );
};

export default Register;
