import { useTranslation } from 'react-i18next';
import { useState,useEffect } from 'react';
import { Link } from "react-router-dom";


const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  
  const mapUserLanguage = (lang) => {
    return lang === "English" ? "en" : lang === "Spanish" ? "es" : i18n.language;
  };


   const [language, setLanguage] = useState(mapUserLanguage(localStorage.getItem('userLanguage')));

   


   useEffect(() => {
    let retryTimeout;
    
  
    const setInitialLanguage = () => {
      const isLoggedIn = localStorage.getItem('Email'); // Check if the user is logged in
      if (!isLoggedIn) return; // Stop if the user is logged out

      const storedLang = localStorage.getItem('userLanguage');
   
      if (storedLang) {
   
        const mappedLang = mapUserLanguage(storedLang);
        i18n.changeLanguage(mappedLang);
        setLanguage(mappedLang);
      } else {
        retryTimeout = setTimeout(setInitialLanguage, 200);
      }
    };
  
    setInitialLanguage(); 
  
    return () => clearTimeout(retryTimeout);
  }, [i18n]);
  



  const changeLanguage = () => {
    const newLang = language === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLang);
    setLanguage(newLang);
  };


  return (
    <>
      <li className="nav-item" style={{display: 'block !important'}}>
        <Link
          className="nav-link header-login px-4"
          onClick={changeLanguage}
          style={{  color: 'inherit',border:"1px solid black" ,borderRadius:"50px", padding: '11px'}}
        >
          <i className="fas fa-globe me-2"></i>
          {language === 'en' ? 'Español' : 'English'}
        </Link>
      </li>
    </>
  );
};

export default LanguageSwitcher;
