import {
  
  CategoryIcon1,
  CategoryIcon2,
  CategoryIcon3,
  CategoryIcon4,
  CategoryIcon5,
  CategoryIcon6,
  CategoryIcon7,
  CategoryIcon8,
} from "../../imagepath";
import { useTranslation } from "react-i18next";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import { Link } from "react-router-dom";

const TopCategory = () => {
  const { t ,i18n} = useTranslation();
  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    gap: 10,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        margin: 25,
      },
      768: {
        items: 3,
        margin: 25,
      },
      1170: {
        items: 4,
        margin: 25,
      },
    },
  };


  return (
    <section className="section how-it-works" key={i18n.language}>
      <div className="container">
        <div className="section-header aos" data-aos="fade-up">
          <div className="section-sub-head">
            <span>{t('whatWeOffer')}</span>
            <h2>{t('onlineLearningAcademy')}</h2>
          </div>
          {/* <div className="all-btn all-category d-flex align-items-center">
            <Link to="#" className="btn btn-primary">
              All Categories
            </Link>
          </div> */}
        </div>
        <div className="section-text aos" data-aos="fade-up">
          <p>
          {t('traditionalEducationIssue')}</p>
        </div>
        <OwlCarousel
          {...settings}
          className="owl-carousel mentoring-course owl-theme aos"
          data-aos="fade-up"
          loop
          margin={10}
          nav
        >
          <div className="feature-box text-center ">
            <div className="feature-bg">
              <div className="feature-header">
                <div className="feature-icon">
                  <img src={CategoryIcon1} alt="" />
                </div>
                <div className="feature-cont">
                  <div className="feature-text">{t('tools')}</div>
                </div>
              </div>
              <p>{t('toolsDescription')}</p>
            </div>
          </div>
          <div className="feature-box text-center ">
            <div className="feature-bg">
              <div className="feature-header">
                <div className="feature-icon">
                  <img src={CategoryIcon2} alt="" />
                </div>
                <div className="feature-cont">
                  <div className="feature-text">{t('training')}</div>
                </div>
              </div>
              <p>{t('trainingDescription')}</p>
            </div>
          </div>
          <div className="feature-box text-center ">
            <div className="feature-bg">
              <div className="feature-header">
                <div className="feature-icon">
                  <img src={CategoryIcon3} alt="" />
                </div>
                <div className="feature-cont">
                  <div className="feature-text">{t('community')}</div>
                </div>
              </div>
              <p>{t('communityDescription')}</p>
            </div>
          </div>
          <div className="feature-box text-center ">
            <div className="feature-bg">
              <div className="feature-header">
                <div className="feature-icon">
                  <img src={CategoryIcon4} alt="" />
                </div>
                <div className="feature-cont">
                  <div className="feature-text"> {t('certifications')}</div>
                </div>
              </div>
              <p>{t('certificationsDescription')}</p>
            </div>
          </div>
          <div className="feature-box text-center ">
            <div className="feature-bg">
              <div className="feature-header">
                <div className="feature-icon">
                  <img src={CategoryIcon5} alt="" />
                </div>
                <div className="feature-cont">
                  <div className="feature-text">{t('realWorldApplication')}</div>
                </div>
              </div>
              <p>{t('realWorldApplicationDescription')}</p>
            </div>
          </div>
          <div className="feature-box text-center ">
            <div className="feature-bg">
              <div className="feature-header">
                <div className="feature-icon">
                  <img src={CategoryIcon6} alt="" />
                </div>
                <div className="feature-cont">
                  <div className="feature-text">
                  {t('effectiveMoneyManagement')}
                  </div>
                </div>
              </div>
              <p>{t('moneyManagementDescription')}</p>
            </div>
          </div>
          <div className="feature-box text-center ">
            <div className="feature-bg">
              <div className="feature-header">
                <div className="feature-icon">
                  <img src={CategoryIcon7} alt="" />
                </div>
                <div className="feature-cont">
                  <div className="feature-text">{t('interestBasedLearning')}</div>
                </div>
              </div>
              <p>{t('interestBasedLearningDescription')}</p>
            </div>
          </div>
          <div className="feature-box text-center ">
            <div className="feature-bg">
              <div className="feature-header">
                <div className="feature-icon">
                  <img src={CategoryIcon8} alt="" />
                </div>
                <div className="feature-cont">
                  <div className="feature-text">{t('inclusiveAccessibility')}</div>
                </div>
              </div>
              <p>{t('inclusiveAccessibilityDescription')}</p>
            </div>
          </div>
         
        </OwlCarousel>
      </div>
    </section>
  );
};

export default TopCategory;
