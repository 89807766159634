import React, { useState, useEffect } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { calculateToolkit5 } from "../../../../../api/programApi";
import {formatNumberWithCommasAndMillions,formatWithDollarAndCommas,stripFormatting} from "../../../../../utils/helper";
import PropTypes from 'prop-types';
import './tool.css';

const Toolkit5 = ({ data }) => {
  
  const [comValue, setComValue] = useState('0');
  const [expectedValue, setExpectedValue] = useState(0);
  const [calculatedData, setCalculatedData] = useState({});

  
 

  useEffect(() => {
    const fetchInitialData = async () => {
      if (data) {
        const initialCalculatedData = await calculateToolkit5(comValue, expectedValue);
        setCalculatedData({
          monthly_value: initialCalculatedData?.monthly_value,
          quarterly_value: initialCalculatedData?.quarterly_value,
        });
      }
    };
    fetchInitialData();
  }, [data]);

  const handleComValueChange = (e) => {
    setComValue(e.target.value); 
  };

  const handleBlur = async () => {
    setComValue((prev) => formatWithDollarAndCommas(prev));
    const response = await calculateToolkit5(stripFormatting(comValue), expectedValue);
    
    setCalculatedData({
        monthly_value: response?.monthly_value,
        quarterly_value: response?.quarterly_value,
    });
  };

  if (!data) {
    return <div>Loading...</div>;
  }


  const getField = (fieldName) => {
    const field = data?.data?.find((item) => item.field_name === fieldName);
    return field ? field : { field_value: '', info: '' };
  };

  const renderFieldWithInfo = (fieldName) => {
    const field = getField(fieldName);

    return (
      <div className="tool-label">
        
        {field.info && (
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id={`tooltip-${fieldName}`}>
                {field.info}
              </Tooltip>
            }
          >
            <i className="fas fa-info-circle me-2" style={{ cursor: 'pointer' }}></i>
          </OverlayTrigger>
        )}
        {field.field_value}
      </div>
    );
  };

  return (
    <div className="share-calculator"> 
      <h3 className="tool-header"><i className="fas fa-calculator me-2"></i>{getField("lesson_header").field_value}</h3>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("quarterly_income_statement")}</div>
        <div className="tool-column">{renderFieldWithInfo("baseline")}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("sales")}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(Number(getField("sales_value").field_value))}</div>
      </div>
      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("cogs")}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(Number(getField("cogs_value").field_value))}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("gross_profit")}</div>
        <div className="tool-column ">${formatNumberWithCommasAndMillions(Number(getField("gross_profit_value").field_value))}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("sales_market")}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(Number(getField("sales_market_value").field_value))}</div>
      </div>
      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("general")}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(Number(getField("general_value").field_value))}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("operating")}</div>
        <div className="tool-column ">${formatNumberWithCommasAndMillions(Number(getField("operating_value").field_value))}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("tax")}</div>
        <div className="tool-column ">${formatNumberWithCommasAndMillions(Number(getField("tax_value").field_value))}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("depre")}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(Number(getField("depre_value").field_value))}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("ocf")}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(Number(getField("ocf_value").field_value))}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("calculator")}</div>
        <div className="tool-column">&nbsp;</div>
      </div>

      <div className="tool-row">
        <label className="tool-column tool-label">{getField("com").field_value}</label>
        <div className="tool-column" style={{border:"none"}}>
            <input
            type="text"
            className="form-input"
            value={comValue}
            onChange={handleComValueChange}
            onBlur={handleBlur}
            />  
        </div>
        </div>

        <div className="tool-row">
        <label className="tool-column tool-label">{getField("expected").field_value}</label>
        <div className="tool-column" style={{border:"none"}}>
            <input
            type="number"
            className="form-input"
            value={expectedValue}
            onChange={(e) => setExpectedValue(e.target.value)}
            onBlur={handleBlur}
            />
        </div>
        </div>  


      <div className="tool-row">
        <div className="tool-column tool-label">{getField("monthly").field_value}</div>
        <div className="tool-column exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.monthly_value)}</div>
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("quarterly").field_value}</div>
        <div className="tool-column exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.quarterly_value)}</div>
      </div>
    </div>
  );
};

Toolkit5.propTypes = {
    data: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          field_name: PropTypes.string.isRequired,
          field_value: PropTypes.string.isRequired,
          info: PropTypes.string, // Optional info field
        })
      ).isRequired,
    }).isRequired,
  };
  

export default Toolkit5;
