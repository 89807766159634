import { useNavigate } from "react-router-dom";

const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    
    localStorage.removeItem("Name");
    localStorage.removeItem("Email");
    localStorage.removeItem("SessionId");
    localStorage.removeItem("allTrainingPrograms");
    localStorage.removeItem("userAvatar");
    localStorage.removeItem("UserId");
		localStorage.removeItem("quizSubmission");
		localStorage.removeItem("trainingProgram");
		localStorage.removeItem("userLanguage");
		localStorage.removeItem("quizSubmissionData");
		localStorage.removeItem("studentDetails");
    
  };

  const handleLogoutAndRedirect = (redirectTo = "/home") => {
    handleLogout();
    navigate(redirectTo);
  };

  return { handleLogoutAndRedirect };
};

export default useLogout;
