import React, { useState,useEffect } from "react";
import { calculateToolkit3 } from "../../../../../api/programApi";
import {formatNumberWithCommas,formatWithDollarAndCommas,stripFormatting} from "../../../../../utils/helper";
import "./tool3.css";
import PropTypes from "prop-types";

const Toolkit3 = ({data}) => {
  const [unitsTwo, setUnitsTwo] = useState(0); 
  const [saleFour, setSaleFour] = useState('0'); 

  const [calculatedData, setCalculatedData] = useState({
    sales_one: 0,
    unit_two: 0,
    sale_two: 0,
    sales_two: 0,
    unit_three: 0,
    sale_three: 0,
    units_three: 0,
    sales_three: 0,
    unit_four: 0,
    units_four: 0,
    sales_four: 0,
    unit_five: 0,
    sale_five: 0,
    units_five: 0,
    sales_five: 0,
    lesson_header: '',
    company_name: '',
    year: '',
    baseline: '',
    day: '',
    unit: '',
    sale: '',
    units: '',
    sales: '',
    avg: '',
    per_day: '',
    per_month: '',
    per_quarter: '',
    per_year: ''
  });

    // Fetch initial calculated data
    useEffect(() => {
      const fetchInitialData = async () => {
        if (data) {
          const initialCalculatedData = await calculateToolkit3(unitsTwo, saleFour);
          setCalculatedData(initialCalculatedData);
        }
      };
      fetchInitialData();
    }, [data]);

    const handleSaleFourChange = (e) => {
      setSaleFour(e.target.value); 
    };


  // Handle input blur
  const handleInputBlur = async () => {
      setSaleFour((prev) => formatWithDollarAndCommas(prev));
      const updatedData = await calculateToolkit3(unitsTwo, stripFormatting(saleFour));
      setCalculatedData(updatedData);
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  const getField = (fieldName) => {
    const field = data?.data?.find(item => item.field_name === fieldName);
    return field ? field.field_value : '';
  };
  

  return (
    <div className="toolkit3-container">
      {/* Title Section */}
      <h3 className="toolkit3-header">
        <i className="fas fa-calculator"></i> {getField('lesson_header')}
      </h3>

      {/* Main Table */}
      <table className="toolkit3-table">
        <thead>
          {/* Company, Year, and Baseline Row */}
          <tr>
            <td>{getField('company_name')}</td>
            <td>{getField('year')}</td>
            <td>{getField('baseline')}</td>
          </tr>

          {/* Header Rows for Units and Sales */}
          <tr>
            <th></th>
            <th>
              <table className="sub-table">
              
                  <tr>
                    <td className="td-tool-label">{getField('day')}</td>
                    <td className="td-tool-label">{getField('unit')}</td>
                    <td className="td-tool-label">{getField('sale')}</td>
                  </tr>
               
              </table>
            </th>
            <th>
              <table className="sub-table">
                
                  <tr>
                    <td className="td-tool-label" >{getField('units')}</td>
                    <td className="td-tool-label">{getField('sales')}</td>
                  </tr>
                
              </table>
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Average Selling Price (ASP) Row */}
          <tr>
            <td>{getField('avg')}</td>
            <td>
              <table className="sub-table">
                <tbody>
                  <tr>
                    <td></td>
                    <td>{getField('unit_one')}</td>
                    <td >${getField('sale_one')}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table className="sub-table">
                <tbody>
                  <tr>
                    <td >{getField('units_one')}</td>
                    <td className="exchange-rate-result-4 bg-dark">${calculatedData.sales_one}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          {/* Per Day Row */}
          <tr>
            <td>{getField('per_day')}</td>
            <td>
              <table className="sub-table">
                <tbody>
                  <tr>
                    <td>{getField('day_two')}</td>
                    <td className="exchange-rate-result-4 bg-dark">{calculatedData.unit_two}</td>
                    <td className="exchange-rate-result-4 bg-dark">${calculatedData.sale_two}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table className="sub-table">
                <tbody>
                  <tr>
                    <td className="bg-white">
                      <input
                        className="form-input-4"
                        type="number"
                        value={unitsTwo}
                        onChange={(e) => setUnitsTwo(e.target.value)}
                        onBlur={handleInputBlur}
                      />
                    </td>
                    <td className="exchange-rate-result-4 bg-dark">${calculatedData.sales_two}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          {/* Per Month Row */}
          <tr>
            <td>{getField('per_month')}</td>
            <td>
              <table className="sub-table">
                <tbody>
                  <tr>
                    <td>{getField('day_three')}</td>
                    <td className="exchange-rate-result-4 bg-dark">{formatNumberWithCommas(calculatedData.unit_three)}</td>
                    <td className="exchange-rate-result-4 bg-dark">${formatNumberWithCommas(calculatedData.sale_three)}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table className="sub-table">
                <tbody>
                  <tr>
                    <td className="exchange-rate-result-4 bg-dark">{formatNumberWithCommas(calculatedData.units_three)}</td>
                    <td className="exchange-rate-result-4 bg-dark">${formatNumberWithCommas(calculatedData.sales_three)}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          {/* Per Quarter Row */}
          <tr>
            <td>{getField('per_quarter')}</td>
            <td>
              <table className="sub-table">
                <tbody>
                  <tr>
                    <td>{getField('day_four')}</td>
                    <td className="exchange-rate-result-4">{formatNumberWithCommas(calculatedData.unit_four)}</td>
                    <td className="bg-white">
                      <input
                        className="form-input-4"
                        type="text"
                        value={saleFour}
                        onChange={handleSaleFourChange}
                        onBlur={handleInputBlur}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table className="sub-table">
                <tbody>
                  <tr>
                    <td className="exchange-rate-result-4 bg-dark">{formatNumberWithCommas(calculatedData.units_four)}</td>
                    <td className="exchange-rate-result-4 bg-dark">${formatNumberWithCommas(calculatedData.sales_four)}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          {/* Per Year Row */}
          <tr>
            <td>{getField('per_year')}</td>
            <td>
              <table className="sub-table">
                <tbody>
                  <tr>
                    <td>{getField('day_five')}</td>
                    <td className="exchange-rate-result-4 bg-dark">{formatNumberWithCommas(calculatedData.unit_five)}</td>
                    <td className="exchange-rate-result-4 bg-dark">${formatNumberWithCommas(calculatedData.sale_five)}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table className="sub-table">
                <tbody>
                  <tr>
                    <td className="exchange-rate-result-4 bg-dark">{formatNumberWithCommas(calculatedData.units_five)}</td>
                    <td className="exchange-rate-result-4 bg-dark">${formatNumberWithCommas(calculatedData.sales_five)}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

};

Toolkit3.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        field_name: PropTypes.string.isRequired,
        field_value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Toolkit3;
